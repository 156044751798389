<template>
  <div class="content">
    <div class="items">
      <div class="item">
        <div class="prod">
          <div class="logo-v">
            <img
              class="logo"
              :src="
                item.user.avatar ||
                'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220613_104414_62a6a47e42202.png'
              "
              alt="用户头像"
            />
          </div>
          <div class="card">
            <span class="title">{{ item.user.nickname }}</span>
            <span class="phone">手机号: {{ item.user.phone }}</span>
          </div>
          <p class="check" v-if="item.status === '1'" @click="check(item)">
            核销
          </p>
          <p class="check check-1" v-else>已核销</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh } from 'vant'
import { setId, getId } from '@/utils/auth'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      loading: false,
      item: {},
    }
  },
  created() {
    if (this.$route.query.id) {
      const id = this.$route.query.id
      setId(id)
    }
    this.queryList()
  },
  methods: {
    check(item) {
      this.$http({ url: '/worker/writeCoupon?id=' + item.id })
        .then(({ msg }) => {
          this.$notify({ type: 'success', message: msg })
          this.queryList()
        })
        .catch(() => {
          this.queryList()
        })
    },
    queryList() {
      this.loading = true
      const id = getId()
      if (id) {
        this.$http('/worker/writeCouponInfo?id=' + getId()).then((res) => {
          this.item = res.data
        })
      } else {
        this.$notify('抱歉,请重新扫码.')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
}
.content {
  padding: 0 32px;
  height: 100vh;
  background: #fafafa;
  .line {
    border-bottom: 1px solid #f1f2f5;
    width: 100vw;
    margin: 0 -32px;
  }
  .items {
    padding: 8px 0;
    .item {
      border-radius: 16px 16px 16px 16px;
      background: #ffffff;
      padding: 16px;
      position: relative;
      margin-bottom: 10px;
      .prod {
        margin: 0 auto;
        display: flex;
        border-radius: 12px 12px 12px 12px;
        position: relative;

        .check {
          position: absolute;
          text-align: center;
          color: #ffffff;
          top: -5px;
          right: 0;
          width: 64px;
          height: 32px;
          line-height: 32px;
          background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
          border-radius: 6px 6px 6px 6px;
          opacity: 1;
          cursor: pointer;
        }
        .check-1 {
          background: rgba(0, 0, 0, 0.26) !important;
        }
        // padding-top: 5px;
        .logo-v {
          width: 50px;
          height: 50px;
          .logo {
            width: 50px;
            height: 50px;
            border-radius: 4px 4px 4px 4px;
          }
        }
        .money-info {
          // margin-right: 10px;
          position: absolute;
          line-height: 60px;
          right: 24px;
          .money {
            height: 60px;
            font-weight: bold;
            color: #242426;
            .symbol {
              font-size: 25px;
            }
            .number {
              font-size: 55px;
            }
          }

          .where {
            display: block;
            text-align: right;
            font-size: 10px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 12px;
            padding-right: 4px;
          }
        }
        .card {
          padding-top: 2px;
          padding-left: 14px;
          .title {
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            display: block;
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .name {
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
          }
          .phone {
            font-size: 12px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
          }

          .time {
            display: block;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 20px;
          }
        }
      }
      .remarks {
        height: 33px;
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        position: relative;
        text {
          line-height: 33px;
        }
        .desc {
          position: absolute;
          left: 14px;
        }
        .use {
          position: absolute;
          right: 24px;
          width: 61px;
          height: 19px;
          line-height: 19px;
          background: #ffffff;
          color: #f56b4e;
          border-radius: 51px 51px 51px 51px;
          top: 7px;
          text-align: center;
        }
      }
      .tip {
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        padding: 5px 24px 10px 14px;
      }
    }
  }
}
</style>
